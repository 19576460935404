import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AdsComponent = ({ dataAdSlot, isTagNeeded = false, adSize = '' }) => {
    // const { handleAdModalLoadingClose } = useContext(NewContext);

    const adElementId = uuidv4();

    const [isAdModalLoading, setIsAdModalLoading] = useState(true);
    const [displayStatus, setDisplayStatus] = useState('block');
    const adRef = useRef(null);

    useEffect(() => {
        // setTimeout(() => setIsAdModalLoading(false), 2000)
        try {
            // (window.adsbygoogle = window.adsbygoogle || []).push({});
            window.adsbygoogle = window.adsbygoogle || []
            window.adsbygoogle.push({})
        }
        catch (e) { }
    }, [dataAdSlot]);
    const [width, height] = adSize.split('x').map(Number);
    const adStyle = width && height ? { width: `${width}px`, height: `${height}px` } : {}

    useEffect(() => {
        const checkAdStatus = () => {
            const adElement = adRef.current;
            const adStatus = adElement?.getAttribute('data-adsbygoogle-status');
            const adFillStatus = adElement?.getAttribute('data-ad-status');
            if (adStatus !== null && (adFillStatus !== null && adFillStatus === 'filled')) {
                setIsAdModalLoading(false);
            } else {
                setIsAdModalLoading(true);
            }
        };
        const observer = new MutationObserver(checkAdStatus);
        if (adRef.current) {
            observer.observe(adRef.current, { attributes: true });
        }

        return () => {
            if (adRef.current) {
                observer.disconnect();
            }
        };
    }, [dataAdSlot]);


    return (
        <div className={`w-[${width}px] min-h-[${height}px] mx-auto ${isAdModalLoading ? 'overflow-hidden' : ''} flex flex-col items-center relative ${isTagNeeded && !isAdModalLoading ? 'pt-1' : ''}`}>
            {isTagNeeded && !isAdModalLoading && <p className='text-[8px] uppercase'>
                Advertisement
            </p>}
            {/* lg:h-[222px] xl:h-[285px] 2xl:h-[344px] */}
            {isAdModalLoading &&
                <div
                    // className="bg-gray-300 m-auto w-full rounded-lg h-full lg:h-[222px] xl:h-[285px] 2xl:h-[344px] animate-wave-skeleton"
                    className={`bg-white overflow-hidden ${isTagNeeded ? 'pt-1' : ''} w-[${width}px] min-h-[${height}px]`}
                    style={{ position: 'absolute', ...adStyle }}
                >
                    {isTagNeeded && <p className='text-[8px] uppercase'>
                        Advertisement
                    </p>}
                    {/* <div className='bg-gray-300 w-full h-full m-auto animate-wave-skeleton'></div> */}
                </div>
            }
            <div className='m-auto' style={{ position: 'relative', ...adStyle }}>
                <ins
                    className={`adsbygoogle ${displayStatus}`}
                    // style={adStyle}
                    style={{ display: "block" }}
                    id={adElementId}
                    // data-ad-client="ca-pub-8383910619750608"
                    data-ad-slot={dataAdSlot}
                    // data-ad-format={adSize}
                    data-full-width-responsive="true"
                    data-ad-format={'auto'}
                    ref={adRef}
                ></ins>
            </div>
        </div >
    );
};

export default AdsComponent;

